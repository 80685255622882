import { selectors, actions, } from '@ezugi/bootstrap';
import {
  filter, prop, values, pick, pluck, compose, applySpec,
} from 'ramda';

import BET_TYPES from '../../../../constants/betTypes';
import { currentBetsSelector, betsHistorySelector, } from '../../../selectors/bets';
import { getTotalBet, } from '.';

const { socketActions, notificationActions, betActions, } = actions;
const { userSelector, tableIdSelector, roundIdSelector, gameSelector, } = selectors;

export const buildParams = (state, bets) => {
  const user = userSelector(state);

  return {
    ClientId: user.clientId,
    ClientIP: user.clientIp,
    CurrentPlayerToken: user.currentPlayerToken,
    MessageType: 'PlaceBet',
    destination: 'player',
    gameType: gameSelector(state).gameId,
    TableId: tableIdSelector(state).toString(),
    roundId: roundIdSelector(state),
    ...bets,
  };
};

const keepValidBets = filter(prop('valid'));

export const createBetsList = compose(
  applySpec({
    BetsList: pick(values(BET_TYPES)),
  }),
  pluck('value')
);

export const createPlaceBetRequestPayload = (state) => {
  const currentBetsList = currentBetsSelector(state);
  const betHistory = betsHistorySelector(state);
  const _actions = [];

  // used in case of reconnection, avoids resending bets
  if (betHistory.length <= 1) return actions;

  const currentBets = values(currentBetsList);

  if (currentBets.length) {
    const validBetsList = keepValidBets(currentBetsList);
    const totalBet = getTotalBet(validBetsList);

    if (currentBets.length > values(validBetsList).length) {
      // Remove invalid bets and update `TotalBet` and `BetHistory`
      _actions.push(notificationActions.notification.add({ message: 'notifications.bet_below_min_limits_removed', }));
      _actions.push(betActions.history.apply({ current: validBetsList, }));
      _actions.push(betActions.totalBet.set({ value: totalBet, }));
    }

    // Cache valid bets for `Rebet`
    _actions.push(betActions.bet.cache({ current: validBetsList, totalBet, }));

    // Send bets
    const params = buildParams(state, createBetsList(validBetsList));
    _actions.push(socketActions.socket.send(params));
  }

  return _actions;
};
