import { selectors, } from '@ezugi/bootstrap';
import {
  add, reduce, map, evolve, prop, mapObjIndexed, compose, values,
} from 'ramda';

import { STATUS, } from '../constants';
import * as BET_TYPES from '../../../../constants/betTypes';


const {
  currentLimitsSelector,
  betsSelector,
} = selectors;

const RESULT_SEED = { ok: true, valid: true, status: STATUS.VALID, };

export const statusPriorityMap = {
  [STATUS.BETTING_NOT_ALLOWED]: 5,
  [STATUS.NOT_ALLOWED]: 4,
  [STATUS.INVALID_BALANCE]: 3,
  [STATUS.OVER_INDEX_LIMIT]: 2,
  [STATUS.OVER_TABLE_LIMIT]: 2,
  [STATUS.BELOW_INDEX_LIMIT]: 1,
  [STATUS.BELOW_TABLE_LIMIT]: 1,
  [STATUS.VALID]: 0,
};


export const getTotalBet = compose(
  reduce(add, 0),
  map(prop('value')),
  values
);

export const getSimpleNotificationPayload = ({ status, value, betAutoAdjust, maxTableLimit, maxBet, minBet, }) => {
  const payload = (() => {
    switch (status) {
    case STATUS.NOT_ALLOWED:
      return { message: 'notifications.bet_not_allowed', };
    case STATUS.INVALID_BALANCE:
      return { message: 'messages.low_balance_message', };
    case STATUS.OVER_TABLE_LIMIT:
      return value
        ? { message: 'notifications.bet_over_table_max_limit_adjusted', }
        : { message: 'notifications.bet_over_table_max_limit', variables: { maxTableLimit, }, };
    case STATUS.OVER_INDEX_LIMIT:
      return { message: 'notifications.bet_over_index_max_limit_adjusted', variables: { maxIndexLimit: maxBet, }, };
    case STATUS.BELOW_INDEX_LIMIT:
      return betAutoAdjust
        ? {
          autoAdjust: true,
          // eslint-disable-next-line
              event_name: STATUS.BELOW_INDEX_LIMIT,
          message: 'notifications.bet_below_index_min_limit_adjusted',
        }
        : { message: 'notifications.bet_below_index_min_limit', variables: { minIndexLimit: minBet, }, };
    default:
      return null;
    }
  })();
  return payload;
};

export const getGroupNotificationPayload = ({ status, }) => {
  const payload = (() => {
    switch (status) {
    case STATUS.NOT_ALLOWED:
      return { message: 'notifications.bet_not_allowed', };
    case STATUS.INVALID_BALANCE:
      return { message: 'messages.low_balance_message', };
    case STATUS.OVER_INDEX_LIMIT:
    case STATUS.OVER_TABLE_LIMIT:
      return { message: 'notifications.group_bet_over_max_limits', };
    case STATUS.BELOW_INDEX_LIMIT:
    case STATUS.BELOW_TABLE_LIMIT:
      return { message: 'notifications.group_bet_below_min_limits', };
    default:
      return null;
    }
  })();

  return payload;
};

export const pipeValidations = (validations) => (bet, state) => reduce(
  (res, fn) => (!res.valid ? res : fn(res.bet, state)),
  {
    ...RESULT_SEED,
    bet,
  },
  validations
);

export const getBetTypeLimits = (() => {
  const cache = {};
  let currentLimits;

  return (type, state) => {
    if (cache[type]) {
      return cache[type];
    }

    currentLimits = currentLimits || currentLimitsSelector(state);

    const limits = { min: 0, max: 0, };

    switch (type) {
    /* MainBets */
    case BET_TYPES.EIGHT:
      limits.min = currentLimits.Min_Group8;
      limits.max = currentLimits.Max_Group8;
      break;

    case BET_TYPES.NINE:
      limits.min = currentLimits.Min_Group9;
      limits.max = currentLimits.Max_Group9;
      break;

    case BET_TYPES.TEN:
      limits.min = currentLimits.Min_Group10;
      limits.max = currentLimits.Max_Group10;
      break;

    case BET_TYPES.ELEVEN:
      limits.min = currentLimits.Min_Group11;
      limits.max = currentLimits.Max_Group11;
      break;

    default:
      limits.min = currentLimits.Min_Bet;
      limits.max = currentLimits.Max_Bet;
      break;
    }

    return limits;
  };
})();

export const doubleBet = (state) => mapObjIndexed((b, key) => {
  const { min, } = getBetTypeLimits(key, state);
  const value = b.value * 2;
  const valid = value >= min;
  return { ...b, value, valid, };
});

export const doubleBets = (state) => compose(
  evolve({
    current: doubleBet(state),
    totalBet: (t) => t * 2,
  }),
  betsSelector
)(state);

export const isOk = prop('ok');
