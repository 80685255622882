import { of, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';

const {
  roundActions: { round, },
} = actions;

export default function handlePlaceYourBets() {
  return of(
    round.set({
      winningHand: null,
      wonBets: {},
      wonMainBets: {},
      wonSideBets: {},
    })
  );
}
