import { handleActions, } from 'redux-actions';

import { statistics, } from '../actions/statistics';

const INITIAL_STATE = {
  winPercentages: {},
  lastWinners: [],
};

export default handleActions(
  {
    [statistics.set]: (state, { payload, }) => (payload),
    [statistics.reset]: () => INITIAL_STATE,
  },
  INITIAL_STATE
);
