import {
  contains, clamp, mapObjIndexed, last, map, all, values, compose, reduce, flatten, prop,
} from 'ramda';
import { selectors, actions, constants, } from '@ezugi/bootstrap';
import { PLACE_YOUR_BETS, LAST_BETS, } from '@ezugi/constants';

import { STATUS, } from '../constants';
import { INITIAL_STATE, } from '../../../reducers/bets';
import { currentBetsSelector, lastBetsSelector, } from '../../../selectors/bets';

import {
  statusPriorityMap,
  isOk,
  pipeValidations,
  doubleBets,
  getBetTypeLimits,
  getSimpleNotificationPayload,
  getGroupNotificationPayload,
} from '.';

const {
  userBalanceSelector,
  totalBetSelector,
  generalConfigSelector,
  betsConfigSelector,
} = selectors;
const {
  betActions,
  dialogActions: { dialog, },
  notificationActions: { notification, },
} = actions;
const { DIALOG: { LOW_BALANCE_DIALOG, } = {}, } = constants;


export const validateRoundStatus = (bet, { round: { roundStatus, }, }) => {
  const ok = contains(roundStatus, [ PLACE_YOUR_BETS, LAST_BETS, ]);
  return {
    ok,
    valid: ok,
    status: ok ? STATUS.VALID : STATUS.BETTING_NOT_ALLOWED,
    bet,
    actions: [],
  };
};

export const validateCoverage = (bet, state) => {
  const currentBets = currentBetsSelector(state);
  const { allowOpposite, } = betsConfigSelector(state) ?? {};

  const ok = allowOpposite || (Object.keys(currentBets)?.length <= 2 || (bet.type in currentBets));
  return {
    ok,
    valid: ok,
    status: ok ? STATUS.VALID : STATUS.BETTING_NOT_ALLOWED,
    bet,
    actions: !ok ? [ notification.add({ message: 'notifications.bet_not_allowed', }), ] : [],
  };
};

export const validateBalance = (bet, state) => {
  const balance = userBalanceSelector(state);
  const totalBet = totalBetSelector(state);

  const ok = balance - totalBet >= bet.value;
  const status = ok ? STATUS.VALID : STATUS.INVALID_BALANCE;

  return {
    ok,
    valid: ok,
    status,
    bet,
    actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
  };
};

export const validateLimits = (bet, state) => {
  const balance = userBalanceSelector(state);
  // bet limits
  const currentBets = currentBetsSelector(state);
  const { min: minBet = 0, max: maxBet = 0, } = getBetTypeLimits(bet.type, state);
  const existingBet = (currentBets[bet.type] || {}).value || 0;

  const { betAutoAdjust, } = generalConfigSelector(state);
  let status = bet.value + existingBet < minBet
    ? STATUS.BELOW_INDEX_LIMIT
    : bet.value + existingBet > maxBet
      ? STATUS.OVER_INDEX_LIMIT
      : STATUS.VALID;

  let ok = status === STATUS.VALID;
  const diff = maxBet - existingBet;

  let value = ok
    ? bet.value
    : clamp(betAutoAdjust ? minBet - existingBet : bet.value > diff ? diff : bet.value, diff, bet.value);

  const totalBet = totalBetSelector(state);

  if (value > balance - totalBet) {
    status = STATUS.INVALID_BALANCE;
    ok = false;
    value = 0;
  }

  const valid = !contains(status, [ STATUS.INVALID_BALANCE, STATUS.BETTING_NOT_ALLOWED, ]);

  const _actions = [
    ...(status === STATUS.INVALID_BALANCE
      ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ]
      : [
        notification.add(
          getSimpleNotificationPayload({
            status,
            value,
            betAutoAdjust,
            minBet,
            maxBet,
          })
        ),
      ]),
  ];

  return {
    ok,
    valid,
    status,
    bet: {
      ...(value && {
        ...bet,
        value,
        valid: !(status === STATUS.BELOW_INDEX_LIMIT && !betAutoAdjust && value + existingBet < minBet),
      }),
    },
    actions: _actions,
  };
};

export function validateBetUndo(_, state) {
  const h = [ ...state.bets.history, ];
  h.pop();
  const s = last(h) || INITIAL_STATE;
  const b = userBalanceSelector(state);

  const ok = s.totalBet <= b;

  return ok
    ? { ok, actions: [ betActions.history.apply({ ...s, history: h, }), ], }
    : { ok, actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ], };
}

export const validateBet = pipeValidations([
  validateRoundStatus,
  validateCoverage,
  validateBalance,
  validateLimits,
]);

export function validateBets(_bets, state) {
  const bets = mapObjIndexed((value, key) => ({
    type: key,
    ...value,
  }))(_bets);

  const results = map((bet) => validateBet(bet, state))(bets);
  const ok = all(isOk, values(results));

  const status = compose(
    reduce((prev, next) => {
      const prevPriority = statusPriorityMap[prev];
      const nextPriority = statusPriorityMap[next];

      return nextPriority > prevPriority ? next : prev;
    }, STATUS.VALID),
    map(prop('status'))
  )(values(results));

  return {
    ok,
    status,
    actions: compose(
      flatten,
      map(({ actions: _actions, }) => _actions),
      values
    )(results),
  };
}

export function validateRebet(_, state) {
  const lb = lastBetsSelector(state);
  const b = userBalanceSelector(state);

  const ok = lb.totalBet <= b;

  return ok
    ? {
      ok,
      actions: [ betActions.bet.apply(lb), ],
    }
    : {
      ok,
      actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
    };
}

export function validateDouble(_, state) {
  const current = currentBetsSelector(state);
  const tb = totalBetSelector(state);
  const b = userBalanceSelector(state);

  const status = tb * 2 > b ? STATUS.INVALID_BALANCE : STATUS.VALID;
  const ok = status === STATUS.VALID;
  const _actions = ok ? [] : [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ];

  let result = {
    status,
    ok,
    valid: ok,
    actions: _actions,
  };

  if (status === STATUS.VALID) {
    result = validateBets(current, state);
    result.actions = result.ok
      ? [ betActions.bet.apply(doubleBets(state)), ]
      : [
        notification.add(
          getGroupNotificationPayload({
            status: result.status,
          })
        ),
      ];
    result.valid = result.ok;
  }

  return result;
}
