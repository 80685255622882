import { map, pluck, filter, } from 'rxjs/operators';
import { ofType, } from 'redux-observable';
import { INITIAL_DATA, GAME_RESULT, } from '@ezugi/constants';
import { actions, } from '@ezugi/bootstrap';

import { statistics, } from '../actions/statistics';

const {
  socketActions: { socket, },
} = actions;

export default function setStatisticsEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ GAME_RESULT, INITIAL_DATA, ].includes(MessageType)),
    pluck('Statistics'),
    map(statistics.set)
  );
}
