import { createActions, } from 'redux-actions';

export default createActions({
  CARDS: {
    ADD: null,
    SET: null,
    RESET: null,
    CANCEL: null,
  },
});
