import { handleActions, } from 'redux-actions';

import * as BET_TYPES from '../../constants/betTypes';
import cardsActions from '../actions/cards';

const INITIAL_STATE = {
  [BET_TYPES.EIGHT]: { value: 8, },
  [BET_TYPES.NINE]: { value: 9, },
  [BET_TYPES.TEN]: { value: 10, },
  [BET_TYPES.ELEVEN]: { value: 11, },
};

export default handleActions(
  {
    [cardsActions.cards.add]: (state, { payload: { hand, card, value, }, }) => ({
      ...state,
      [hand]: { cards: [ ...(state[hand].cards ?? []), card, ], value, },
    }),
    [cardsActions.cards.set]: (state, { payload, }) => ({ ...state, ...payload, }),
    [cardsActions.cards.cancel]: (state, { payload: { CardHand, CardName, CardHandValue, }, }) => ({
      ...state,
      [CardHand]: {
        cards: state[CardHand]?.cards?.filter?.((c) => c.CardName !== CardName),
        value: CardHandValue,
      },
    }),
    [cardsActions.cards.reset]: () => INITIAL_STATE,
  },
  INITIAL_STATE,
);
