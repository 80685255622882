import { css, } from 'react-emotion';
import { theme, } from '@ezugi/bootstrap';
import tinyColor from 'tinycolor2';

import { isHandheld, } from '../../utils';

const { palette, } = theme;

const strokeColor = tinyColor(palette.highlight)
  .brighten(50)
  .toString();

export const resultMessage = css`
  > div:nth-child(3) {
    > div {
      color: ${palette.highlight};
      font-size: ${isHandheld ? 40 : 64}px;
      font-weight: 900;
      text-align: center;
      text-shadow: 0 6px 13px 0 #000, 0 0 2px ${strokeColor};
      -webkit-text-stroke: 1px ${strokeColor};
      transform: translateY(-10%);
    }
  }
`;
