import { actions, selectors, } from '@ezugi/bootstrap';
import { ofType, } from 'redux-observable';
import { switchMap, map, } from 'rxjs/operators';
import {
  EIGHT, NINE, TEN, ELEVEN,
} from '../../../constants/betTypes';

const { auth, } = actions.authActions;
const { authSelector, gameSelector, } = selectors;
const { config, } = actions.configActions;

const {
  // newline
  REACT_APP_API_PORT,
  REACT_APP_API_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_FETCH_PAYOUTS,
} = process.env;
const PORT = REACT_APP_API_PORT;
const BASE_URL = `${REACT_APP_API_PROTOCOL}://${REACT_APP_API_HOST}:${PORT}`;
const payoutsUrl = `${BASE_URL}${REACT_APP_API_FETCH_PAYOUTS}`;

const betTypes = [ EIGHT, NINE, TEN, ELEVEN, ];

function payoutsEpic(action$, state$) {
  return action$.pipe(
    ofType(auth.success),
    switchMap(() => {
      const { identifier, } = authSelector(state$.value);
      const { gameId, } = gameSelector(state$.value);
      return fetch(`${payoutsUrl}/?identifier=${identifier}&game_id=${gameId}&raw=true`,
        { credentials: 'include', }).then((res) => res.json());
    }),
    map(
      (value) => value.result.payouts.reduce((acc, p) => {
        const label = betTypes[p.IndexID];
        const payout = JSON.parse(p.payout).option1;
        acc.push({ label, payout, });
        return acc;
      }, [])
    ),
    map((payouts) => config.set({ payouts, }))
  );
}

export default payoutsEpic;
