import {
  socketEpic,
  payoutsEpic,
  betsEpic,
  betsReducer,
  cardsEpic,
  cardsReducer,
  statisticsReducer,
  statisticsEpic,
} from './store';
import * as resultMessageStyles from './components/ResultMessage/styles';
import { isHandheld, } from './utils';

export default () => ({
  components: {
    GameBoard: () => Promise.resolve({ default: () => null, }),
    VideoOverlay: () => import('./views/VideoOverlay'),
    LeftSideDrawerContent: () => import('./components/LeftSideDrawerContent'),
    RightSideDrawerContent: () => import('./components/RightSideDrawerContent'),
    Payouts: () => import('./components/Payouts'),
    ResultDisplay: () => import('./components/ResultDisplay'),
    WelcomeDialog: () => import('./components/WelcomeDialog'),
    Statistics: () => import('./components/Statistics'),
    GameInfo: () => import('./components/GameInfo'),
  },
  props: {
    ResultMessage: { className: resultMessageStyles.resultMessage, },
    BetToolbar: { style: { zIndex: 1, }, },
    GameResults: { hideWinnerList: true, },
    ...(!isHandheld && { GameNotifications: { style: { marginTop: '36%', }, }, }),
  },
  store: {
    epics: [ socketEpic, payoutsEpic, betsEpic, cardsEpic, statisticsEpic, ],
    reducers: { bets: betsReducer, cards: cardsReducer, statistics: statisticsReducer, },
  },
  landscapeToolbarOrientation: 'vertical',
});
