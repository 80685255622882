import { pluck, filter, map, } from 'rxjs/operators';
import { combineEpics, ofType, } from 'redux-observable';

import { actions, } from '@ezugi/bootstrap';
import {
  INITIAL_DATA, PLACE_YOUR_BETS, CARD, CANCEL_LAST_CARD,
} from '@ezugi/constants';

import cardsActions from '../../actions/cards';

const {
  socketActions: { socket, },
  roundActions: { round, },
} = actions;

const cardsInitEpic = (action$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => MessageType === INITIAL_DATA),
  map(({ CardHands, }) => cardsActions.cards.set(CardHands.reduce((acc, {
    CardHand, CardHandValue: value, CardsList: cards,
  }) => {
    acc[CardHand] = { cards, value, };
    return acc;
  }, {})))
);

const cardMessageEpic = (action$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => MessageType === CARD),
  map((socketMessage) => {
    const { CardHand, CardName, CardValue, CardHandValue, } = socketMessage;
    const card = { CardName, CardValue, };
    return cardsActions.cards.add({
      hand: CardHand,
      card,
      value: CardHandValue,
    });
  })
);

const cardsResetEpic = (action$) => action$.pipe(
  ofType(round.set),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === PLACE_YOUR_BETS),
  map(() => cardsActions.cards.reset())
);

const cancelCardEpic = (action$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => MessageType === CANCEL_LAST_CARD),
  map(cardsActions.cards.cancel)
);


export default combineEpics(cardsInitEpic, cardMessageEpic, cardsResetEpic, cancelCardEpic);
